import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { createPagePath } from '../utils'
import { BioButton } from './shared'
import { getLocale, getLocaleUrl } from '../utils/localeUtils'
import useIsMobileDetect from '../utils/useIsMobileDetect'

const CollectionHero = ({ children }) => {
  return (
    <div className="w-full mb-24" data-cy="collectionHero">
      <section className="flex justify-between w-full">{children}</section>
    </div>
  )
}

export const Item = ({
  leftItem,
  rightItem,
  contentItem,
  whiteFont,
  headlineTextShadow,
  subTitleUp
}) => {
  const subTitleElem = (
    <p className="subheadline text-sm md:text-2xl">
      {contentItem.subline || contentItem?.teaserContent?.subtitle}
    </p>
  )

  const mobile = useIsMobileDetect(600)
  return (
    <AnchorWrapper
      contentItem={contentItem}
      teaserLink={
        contentItem.customFile ||
        contentItem.customUrl ||
        createPagePath({ item: contentItem?.teaserContent })
      }
      rightItem={rightItem}
      leftItem={leftItem}>
      <BgImage
        Tag="div"
        image={
          mobile && contentItem.customImageMobile
            ? getImage(contentItem.customImageMobile?.asset)
            : getImage(contentItem.customImage?.image?.asset) ||
              getImage(contentItem?.teaserContent?.previewImage?.image?.asset)
        }
        alt={
          contentItem.customImage?.image?.alt ||
          contentItem?.teaserContent?.previewImage?.image?.alt
        }
        className="flex justify-center items-center w-full h-full px-8">
        <div
          className={classNames(
            'text-center uppercase',
            whiteFont && 'text-white',
            leftItem && 'md:w-2/3'
          )}>
          {subTitleUp && subTitleElem}
          <h2
            className={classNames(
              'text-sm md:text-2xl',
              headlineTextShadow && 'headline-text-shadow-bold'
            )}>
            {contentItem.title || contentItem?.teaserContent?.title}
          </h2>
          {!subTitleUp && subTitleElem}
        </div>
        {contentItem.button && (
          <div className="hidden lg:block w-full lg:bottom-30 absolute bottom-25- text-center">
            <BioButton button={contentItem.button} style="isSmall" />
          </div>
        )}
      </BgImage>
    </AnchorWrapper>
  )
}

const AnchorWrapper = ({
  contentItem,
  teaserLink,
  children,
  rightItem,
  leftItem
}) => {
  const locale = getLocale()
  const anchorWrapperStyles = classNames(
    'block w-1/2 md:w-auto flex-auto h-64 md:h-100 rounded-box-default overflow-hidden',
    rightItem && 'md:flex-basis-1/3',
    leftItem && 'mr-4 md:mr-8'
  )
  if (contentItem.customLink || contentItem?.customFile?.asset?.url) {
    return (
      <a
        className={anchorWrapperStyles}
        href={contentItem.customLink || contentItem?.customFile?.asset?.url}
        rel="noreferrer noopener">
        {children}
      </a>
    )
  } else {
    if (typeof teaserLink !== 'string') return null
    return (
      <Link
        className={anchorWrapperStyles}
        to={getLocaleUrl(locale, teaserLink)}>
        {children}
      </Link>
    )
  }
}

export default CollectionHero
